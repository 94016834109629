import React from 'react';
import { hydrate } from 'react-dom';
import Faq from '../server/components/TeliaACEKnowledge';

const initialStateFaq = window.__INITIAL_STATE_FAQ__;
delete window.__INITIAL_STATE_FAQ__;
const faqRoot = document.getElementById('faq-app');

if (faqRoot) {
  hydrate((
    <Faq
      interfaceUrl={initialStateFaq.interfaceUrl}
    />
  ), faqRoot);
}
