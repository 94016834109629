/* eslint react/no-danger: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import './teliaACEKnowledge.scss';

const getScript = () => {
  return {
    __html: `
    (function(a,b,c,d,e,f,g){for(var h,i=/[?&]{1}(humany[^=]*)=([^&#]*)/g;h=i.exec(a.location.search);)c+=(-1<c.indexOf("?")?"&":"?")+h[1]+"="+h[2];
    f=b.createElement(e),f.async=!0,f.src=c,g=b.getElementsByTagName(e)[0],g.parentNode.insertBefore(f,g),a[d]=a[d]||{_c:[],
    configure:function(){a[d]._c.push(arguments)}};var j=d.toLowerCase();a[j]=a[j]||{_c:[],configure:function(){a[j]._c.push(arguments)}}})
    (window,document,"//ving.humany.net/default/embed.js","Humany","script");
    `,
  };
};

const TeliaACEKnowledge = ({ interfaceUrl }) => {
  return (
    <div className="faq-container">
      <script type="text/javascript" dangerouslySetInnerHTML={getScript()} />
      <a href={interfaceUrl}>FAQ</a>
    </div>
  );
};

TeliaACEKnowledge.propTypes = {
  interfaceUrl: PropTypes.string.isRequired,
};

export default TeliaACEKnowledge;
